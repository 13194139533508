"use client"

import type { FunctionComponent } from "react"

import classnames from "classnames"
import dynamic from "next/dynamic"
import { useState } from "react"

import { Icon } from "@/modules/headerFooterFO/Header/components/Icon/Icon"
import { useJobAdDetailTracking } from "@/modules/jobAdDetail/hooks/useJobAdDetailTracking"

import styles from "./LoginButton.module.css"

const DynamicLoginModal = dynamic(
  () => import("@/modules/careerCenter/components/LoginModal/NewLoginModal").then(mod => mod.NewLoginModal),
  { ssr: false }
)

type UIPosition = "header" | "nav"

export type LoginButtonProps = {
  careerCenterName: string
  className?: string
  isWwwCareerCenter: boolean
  label: string
  uiPosition?: UIPosition
}

export const LoginButton: FunctionComponent<LoginButtonProps> = ({
  careerCenterName,
  className,
  isWwwCareerCenter,
  label,
  uiPosition,
}) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const { commonRefIdsProps } = useJobAdDetailTracking()

  const openLoginModal = (): void => {
    setIsLoginModalOpen(true)
  }

  const closeLoginModal = (): void => {
    setIsLoginModalOpen(false)
  }

  return (
    <>
      <button
        className={classnames(styles.main, className, { [styles.main___inNav]: uiPosition === "nav" })}
        type="button"
        onClick={openLoginModal}
        data-testid="authentication__LoginButton"
      >
        <span className={styles.icon} aria-hidden>
          <Icon name="Profile" />
        </span>
        <span className={classnames(styles.label, { [styles.label___visibleOnDesktopOnly]: uiPosition === "header" })}>
          {label}
        </span>
      </button>

      {isLoginModalOpen && (
        <DynamicLoginModal
          careerCenterName={careerCenterName}
          isWwwCareerCenter={isWwwCareerCenter}
          onClose={closeLoginModal}
          trackingRefIdsProps={commonRefIdsProps}
        />
      )}
    </>
  )
}
